import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import classnames from "classnames";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import Header from "components/Header";
import Loading from "components/Loading";

import api from "config/api";
import Swal from "sweetalert2";

export default function Instructions() {
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(0);
  const [products, setProducts] = useState([]);
  const [variations, setVariations] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetchData(tabs);
  }, [tabs]);

  async function fetchData(categoryType) {
    const nuvemShopIdStore = sessionStorage.getItem("storeId");
    setLoading(true);
    try {
      const { data } = await api.get(
        `/product?categoryType=${categoryType}&nuvemShopIdStore=${nuvemShopIdStore}`
      );
      const res = await api.get(
        `/variation?categoryType=${categoryType}&nuvemShopIdStore=${nuvemShopIdStore}`
      );

      setProducts(data);
      setVariations(res.data);
    } catch (error) {
      if (error.response.status === 401) {
        console.log(error.response.status);
        Swal.fire(
          "Token Expirado",
          "Por favor, efetue login novamente!",
          "info"
        ).then(() => {
          sessionStorage.clear();
          history.push("/admin");
        });
      } else {
        Swal.fire(
          "Erro",
          "Ocorreu um Erro ao carregar os dados, contate a equipe técnica!",
          "error"
        ).then(() => {
          history.push("/admin/receitas");
        });
      }
    }
    setLoading(false);
  }

  return (
    <>
      <Header />
      {loading && <Loading />}
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent text-default">
                <strong>Instruções</strong>
              </CardHeader>
              <CardBody>
                <Nav
                  pills
                  role="tablist"
                  className="nav-fill flex-column flex-md-row"
                >
                  <NavItem>
                    <NavLink
                      aria-selected={tabs === 0}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: tabs === 0,
                      })}
                      role="tab"
                      onClick={() => setTabs(0)}
                      href="#"
                    >
                      Lentes de Grau
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={tabs === 1}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: tabs === 1,
                      })}
                      role="tab"
                      onClick={() => setTabs(1)}
                      href="#"
                    >
                      Lentes de Descanso
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={tabs === 2}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: tabs === 2,
                      })}
                      role="tab"
                      onClick={() => setTabs(2)}
                      href="#"
                    >
                      Solar com Grau
                    </NavLink>
                  </NavItem>
                </Nav>
                {products.length > 0 && (
                  <>
                    <h5 className="mt-2">Produtos</h5>
                    <ul style={{ listStyleType: "none" }} className="mt-3">
                      {products.map((product) => {
                        if (product.published) {
                          return (
                            <li className="mt-1" key={product.id}>
                              <a href={`/admin/produto/${product.id}`}>
                                {product.name.pt}
                              </a>
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </ul>
                  </>
                )}
                {variations.length > 0 && (
                  <>
                    <h5>Variações</h5>
                    <ul style={{ listStyleType: "none" }} className="mt-3">
                      {variations.map((variation) => (
                        <li className="mt-1" key={variation.idVariation}>
                          <a href={`/admin/variacao/${variation.idVariation}`}>
                            {variation.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
