import React from "react";

import {
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import "./styles.scss";

export default function SearchBar(props) {
  return (
    <div>
      <FormGroup>
        <InputGroup className="mb-4">
          <Input onKeyDown={props.onkeydown} onChange={props.onchange} value={props.value} placeholder="Pesquisa" type="text" />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <div onClick={props.onclick} className="search-button">
                <i className="fas fa-search" />
              </div>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
    </div>
  );
}
