import React from "react";
import { Link, useHistory } from "react-router-dom";

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Button
} from "reactstrap";
import Swal from "sweetalert2";

export default function AdminNavbar(props) {
  const history = useHistory();

  function logOut(e) {
    e.preventDefault();

    Swal.fire({
      title: "Sair?",
      text: "Deslogar da sua conta?",
      showCancelButton: true,
      cancelButtonText: "Não sair",
      confirmButtonText: "Sair",
      confirmButtonColor: "rgba(244, 20, 20, 0.74)",
    }).then((res) => {
      if (res.value) {
        sessionStorage.clear();

        history.push("/login");
      }
    });
  }
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle bg-transparent">
                    <i className="ni ni-single-02" />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {sessionStorage.getItem("name")}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem-vindo!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Configurações</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag={Button} href="#" onClick={(e) => logOut(e)}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
