import Prescriptions from "views/admin/Prescriptions";
import Settings from "views/admin/Settings";
import UserProfile from "views/admin/UserProfile";
import Variations from "views/admin/Variations";
import Instructions from "views/admin/Instructions";
import Product from "views/admin/Product";
import Variation from "views/admin/Variation";

var routes = [
  {
    path: "/receitas",
    name: "Receitas",
    icon: "ni ni-single-copy-04",
    component: Prescriptions,
    layout: "/admin",
  },
  {
    path: "/configuracoes",
    name: "Configurações",
    icon: "ni ni-settings",
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Perfil",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/variacoes",
    name: "Variações",
    icon: "ni ni-tag",
    component: Variations,
    layout: "/admin",
  },
  {
    path: "/instrucoes",
    name: "Instruções",
    icon: "ni ni-single-copy-04",
    component: Instructions,
    layout: "/admin",
  },
  {
    path: "/produto/:id",
    name: "Produto",
    invisible: true,
    component: Product,
    layout: "/admin",
  },
  {
    path: "/variacao/:id",
    name: "Variação",
    invisible: true,
    component: Variation,
    layout: "/admin",
  },
];
export default routes;
