import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.jsx";
import Register from "views/authentication/Register";
import Login from "views/authentication/Login";
import ForgotPassword from "views/authentication/ForgotPassword";
import RecoverPassword from "views/authentication/RecoverPassword";

import isAuth from "./auth/auth";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/register" render={(props) => <Register {...props} />} />
      <Route path="/recover-password/:token/:email" component={RecoverPassword} />
      <Route
        path="/forgot-password"
        render={(props) => <ForgotPassword {...props} />}
      />
      <Route path="/login" render={(props) => <Login {...props} />} />
      <PrivateRoute
        path="/admin"
        component={(props) => <AdminLayout {...props} />}
      />
      <Redirect from="/" to="/admin/receitas" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
