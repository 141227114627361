import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Modal,
} from "reactstrap";

import Header from "components/Header";
import Loading from "components/Loading";
import SearchBar from "components/SearchBar";
import CustomPagination from "components/CustomPagination";

import api from "config/api";

export default function Receitas() {
  const limit = 10;
  const [picIsOpen, setPicIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prescriptions, setPrescriptions] = useState([]);
  const [originalDomain, setOriginalDomain] = useState("");
  const [modal, setModal] = useState(false);
  const [url, setUrl] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const history = useHistory();

  useEffect(() => {
    fetchPrescriptions();
  }, [page]);

  function toggleModal() {
    setModal(!modal);
  }

  async function fetchPrescriptions() {
    setLoading(true);
    const domain = await sessionStorage.getItem("domain");
    setOriginalDomain(domain);
    try {
      var url = `/prescription?offset=${page - 1}`;
      if (search) {
        url = url.concat(`&search=${search}`);
      }
      const { data } = await api.get(url);

      setPrescriptions(data.data);
      setTotal(data.total);
    } catch (error) {
      if (error.response.status === 401) {
        Swal.fire(
          "Token Expirado",
          "Por favor, efetue login novamente!",
          "info"
        ).then(() => {
          sessionStorage.clear();
          history.push("/admin");
        });
      } else {
        Swal.fire(
          "Erro",
          "Ocorreu um Erro ao carregar os dados, contate a equipe técnica!",
          "error"
        );
      }
    }
    setLoading(false);
  }

  function confirmRedirect(cpf) {
    setUrl(`https://${originalDomain}/admin/customers?q=${cpf}`);
    toggleModal();
  }

  function openPicture(urlImage) {
    setImgUrl(urlImage);
    setPicIsOpen(true);
  }

  async function searchPrescription() {
    fetchPrescriptions();
  }

  // Formatar cpf/cnpj
  function formatarCampo(campoTexto) {
    if (campoTexto.length <= 11) {
      campoTexto = mascaraCpf(campoTexto);
    } else {
      campoTexto = mascaraCnpj(campoTexto);
    }
    return campoTexto;
  }
  function mascaraCpf(valor) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }
  function mascaraCnpj(valor) {
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }

  return (
    <>
      <Header />
      {loading && <Loading />}
      {/* Image Lightbox */}
      {picIsOpen && (
        <Lightbox mainSrc={imgUrl} onCloseRequest={() => setPicIsOpen(false)} />
      )}
      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => toggleModal()}
      >
        <h3 className="text-center m-4">Tem certeza?</h3>
        <h6 className="text-center">
          Deseja abrir os detalhes do cliente na plataforma nuvemshop?
        </h6>
        <Col xs={12}>
          <a target="blank" href={url}>
            <Button
              onClick={() => toggleModal()}
              className="my-2"
              color="primary"
              block
            >
              Abrir
            </Button>
          </a>
        </Col>
        <Col xs={12}>
          <Button
            onClick={() => toggleModal()}
            className="mb-3"
            color="secondary"
            block
          >
            Cancelar
          </Button>
        </Col>
      </Modal>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                <strong>Receitas</strong>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12} md={4} className="ml-md-3">
                    <SearchBar
                      onkeydown={(e) => {
                        if (e.keyCode === 13) {
                          searchPrescription();
                        }
                      }}
                      onchange={(e) => setSearch(e.target.value)}
                      value={search}
                      onclick={() => searchPrescription()}
                    />
                  </Col>
                </Row>
                <div>
                  <Table
                    striped
                    className="d-none d-md-table align-items-center text-center"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Celular</th>
                        <th>Email</th>
                        <th>CPF</th>
                        <th>Receita</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!prescriptions &&
                        prescriptions.map((prescription) => (
                          <tr key={prescription.idPrescription}>
                            <td>{prescription.idPrescription}</td>
                            <td>{prescription.name}</td>
                            <td>{prescription.cellphone}</td>
                            <td>{prescription.email}</td>
                            <td>
                              <a
                                href={`https://${originalDomain}/admin/customers?q=${prescription.cpf}`}
                                target="blank"
                              >
                                {formatarCampo(prescription.cpf)}
                              </a>
                            </td>
                            {prescription.fileImageName ? (
                              <td>
                                {prescription.urlImage.substr(-3) === 'pdf' ?
                                  <a
                                    href={prescription.urlImage}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      color: '#525f7f'
                                    }}
                                  >
                                    <i className="ni ni-camera-compact" />
                                Ver Receita
                              </a>
                                  : <div
                                    onClick={() =>
                                      openPicture(prescription.urlImage)
                                    }
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i className="ni ni-camera-compact" />
                                  Ver Receita
                                </div>}

                              </td>
                            ) : (
                                <td>Vai Enviar Depois</td>
                              )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <ul style={{ padding: 0 }} className="d-md-none">
                    {!!prescriptions &&
                      prescriptions.map((prescription) => (
                        <>
                          <Row
                            key={prescription.idPrescription}
                            className="mt-3 pb-2"
                            onClick={() => confirmRedirect(prescription.cpf)}
                          >
                            <Col
                              xs={3}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-around",
                              }}
                            >
                              <Row>
                                {prescription.fileImageName ? (
                                  prescription.urlImage.substr(-3) === 'pdf' ?
                                    <a
                                      onClick={(e) => e.stopPropagation()}
                                      href={prescription.urlImage}
                                      rel="noreferrer noopener"
                                      target="_blank"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        textAlign: "center",
                                        color: '#525f7f'
                                      }}
                                    >
                                      <i className="ni ni-camera-compact" />
                                      <small style={{ lineHeight: 1 }}>
                                        Ver Receita
                                    </small>
                                    </a>
                                    : <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openPicture(prescription.urlImage);
                                      }}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        textAlign: "center",
                                      }}
                                    >
                                      <i className="ni ni-camera-compact" />
                                      <small style={{ lineHeight: 1 }}>
                                        Ver Receita
                                    </small>
                                    </div>
                                ) : (
                                    <small className="text-center">
                                      Vai Enviar Depois
                                    </small>
                                  )}
                              </Row>
                              <Row>
                                <small>Id: {prescription.idPrescription}</small>
                              </Row>
                            </Col>
                            <Col xs={9}>
                              <Row className="ml-1 text-bold">
                                <strong>{prescription.name}</strong>
                              </Row>
                              <Row className="ml-1">
                                <small>
                                  cpf: {formatarCampo(prescription.cpf)}
                                </small>
                              </Row>
                              <Row className="ml-1">
                                <small>{prescription.cellphone}</small>
                              </Row>
                            </Col>
                          </Row>
                          <Row
                            style={{
                              borderBottom: "solid 1px #ccc",
                              paddingBottom: "5px",
                            }}
                            className="ml-1"
                          >
                            <small className="ml-1">{prescription.email}</small>
                          </Row>
                        </>
                      ))}
                  </ul>
                </div>
                {total > 10 && (
                  <div className="d-flex my-3 justify-content-center">
                    <CustomPagination
                      total={total}
                      page={page}
                      limit={limit}
                      length={prescriptions.length}
                      setPage={setPage}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
