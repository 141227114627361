import React, { useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  CardImg,
  Alert,
} from "reactstrap";

import logo from "assets/img/logo_dark_crm.png";
import api from "config/api";
import Loading from "components/Loading";

export default function Login() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token, email } = useParams();
  const history = useHistory();

  async function submit(e) {
    e.preventDefault();

    if (password.length < 6) {
      setError("A senha precisa ter no mínimo 6 dígitos");
      return;
    }
    if (password !== confirmPassword) {
      setError("As senhas não coincidem");
      return;
    }
    if (password.length > 18) {
      setError("A senha não deve conter mais que 18 dígitos");
      return;
    }

    setLoading(true);
    try {
      await api.post("reset-password", {
        email,
        password,
        token,
      });

      Swal.fire("Sucesso", "Senha alterada com sucesso", "success").then(() => {
        history.push("/login");
      });
    } catch (error) {
      setError(
        "Solicite outra alteração de senha ou entre em contato com a equipe técnica"
      );
    }
    setLoading(false);
  }
  return (
    <div className="div-container">
      {loading && <Loading />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-transparent p-5"
          >
            <CardImg src={logo} alt="logo" />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div>
              <Link to="/login">
                <i className="fa fa-arrow-left" /> Voltar
              </Link>
            </div>
            <div className="text-center text-muted mb-4">
              <small>Criar nova senha</small>
            </div>
            {error && (
              <Alert
                style={{
                  backgroundColor: "#d32c2c47",
                  border: "none",
                  color: "#000",
                }}
              >
                <p>
                  <strong>Erro!</strong> {error}
                </p>
              </Alert>
            )}
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Senha"
                    type="password"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13)
                        document
                          .getElementsByName("confirmPassword")[0]
                          .focus();
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirmar Senha"
                    name="confirmPassword"
                    type="password"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) submit();
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  onClick={(e) => submit(e)}
                  className="my-4"
                  color="primary"
                  type="button"
                >
                  Alterar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}
