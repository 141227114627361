import axios from "axios";

const api = axios.create({
  baseURL: "https://www.monte-seu-oculos-api.plataformaeva.com/",
});

api.interceptors.request.use(async (config) => {
  const token = await sessionStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
