import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Button,
  Label,
} from "reactstrap";

import Header from "components/Header";
import Loading from "components/Loading";

import api from "config/api";

export default function Variation() {
  const [loading, setLoading] = useState(false);
  const [instruction, setInstruction] = useState("");
  const [variation, setVariation] = useState({});
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchVariation();
  }, []);

  async function fetchVariation() {
    setLoading(true);
    try {
      const { data } = await api.get(`/variation/${id}`);

      setVariation(data);
      if (data.instruction) {
        setInstruction(data.instruction);
      }
    } catch (error) {
      if (error.response.status === 401) {
        console.log(error.response);
        Swal.fire(
          "Token Expirado",
          "Por favor, efetue login novamente!",
          "info"
        ).then(() => {
          sessionStorage.clear();
          history.push("/admin");
        });
      } else {
        Swal.fire(
          "Erro",
          "Ocorreu um Erro ao carregar os dados, contate a equipe técnica!",
          "error"
        ).then(() => {
          history.push("/admin/instrucoes");
        });
      }
    }
    setLoading(false);
  }

  async function submit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await api.put(`/variation/${id}`, {
        instruction,
      });

      Swal.fire("Sucesso", "Instrução alterada com sucesso!", "success").then(
        () => {
          history.push("/admin/instrucoes");
        }
      );
    } catch (error) {
      Swal.fire(
        "Erro",
        "Ocorreu um Erro ao salvar os dados, contate a equipe técnica!",
        "error"
      );
    }
    setLoading(false);
  }
  return (
    <>
      <Header />
      {loading && <Loading />}
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent text-default">
                <strong>Instruções de Variação</strong>
              </CardHeader>
              <CardBody>
                <div className="mb-3">
                  <Link to="/admin/instrucoes">
                    <i className="fa fa-arrow-left" /> Voltar
                  </Link>
                </div>
                {variation.idVariation && (
                  <>
                    <Label className="mb-0 mt-2">Nome da Variação:</Label>
                    <Input type="text" readOnly value={variation.name} />
                    <Label className="mb-0 mt-2">Instrução: </Label>
                    <Input
                      type="textarea"
                      value={instruction}
                      onChange={(e) => setInstruction(e.target.value)}
                    />
                  </>
                )}
                <Button
                  type="button"
                  onClick={(e) => submit(e)}
                  className="float-right mt-3"
                  color="success"
                  outline
                >
                  Salvar
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
