import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import Swal from "sweetalert2";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  Input,
  Button,
  Alert,
} from "reactstrap";

import Header from "components/Header";
import Flag from "components/Flag";
import Loading from "components/Loading";

import api from "config/api";

export default function Variations() {
  const [tabs, setTabs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [variations, setVariations] = useState([]);
  const [name, setName] = useState("");
  const [variationType, setVariationType] = useState(0);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    fetchVariations();
  }, []);

  async function fetchVariations() {
    const nuvemShopIdStore = sessionStorage.getItem("storeId");
    setLoading(true);
    try {
      const { data } = await api.get(
        `/variation?nuvemShopIdStore=${nuvemShopIdStore}`
      );

      setVariations(data);
    } catch (error) {
      if (error.response.status === 401) {
        console.log(error.response.status);
        Swal.fire(
          "Token Expirado",
          "Por favor, efetue login novamente!",
          "info"
        ).then(() => {
          sessionStorage.clear();
          history.push("/admin");
        });
      } else {
        Swal.fire(
          "Erro",
          "Ocorreu um Erro ao carregar os dados, contate a equipe técnica!",
          "error"
        ).then(() => {
          history.push("/admin/receitas");
        });
      }
    }
    setLoading(false);
  }

  async function submit(e) {
    e.preventDefault();

    if (!name) {
      setError("O nome não pode ser vazio");
      return;
    }
    const categoryType = tabs;

    setLoading(true);
    try {
      await api.post("/variation", {
        categoryType,
        name,
        variationType,
      });

      setName("");
      setError("");
      fetchVariations();
    } catch (error) {
      if (error.response.status === 401) {
        console.log(error.response.status);
        Swal.fire(
          "Token Expirado",
          "Por favor, efetue login novamente!",
          "info"
        ).then(() => {
          sessionStorage.clear();
          history.push("/admin");
        });
      } else {
        setError(
          "Erro ao salvar, tente novamente ou contate a equipe técnica!"
        );
      }
    }
    setLoading(false);
  }

  async function deleteVariation(id) {
    Swal.fire({
      title: "Tem certeza?",
      text: `Deseja remover ${name} ?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Deletar",
      confirmButtonColor: "#f5365c",
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.delete(`/variation/${id}`);

          setVariations(
            variations.filter((variation) => {
              return variation.idVariation !== id;
            })
          );
        } catch (error) {
          if (error.response.status === 401) {
            console.log(error.response.status);
            Swal.fire(
              "Token Expirado",
              "Por favor, efetue login novamente!",
              "info"
            ).then(() => {
              sessionStorage.clear();
              history.push("/admin");
            });
          } else {
            Swal.fire(
              "Erro",
              "Ocorreu um Erro ao deletar dados, contate a equipe técnica!",
              "error"
            );
          }
        }
        setLoading(false);
      }
    });
  }

  return (
    <>
      <Header />
      {loading && <Loading />}
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent">
                <strong>Variações</strong>
              </CardHeader>
              <CardBody>
                <Nav
                  pills
                  role="tablist"
                  className="nav-fill flex-column flex-md-row"
                >
                  <NavItem>
                    <NavLink
                      aria-selected={tabs === 0}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: tabs === 0,
                      })}
                      role="tab"
                      onClick={() => setTabs(0)}
                      href="#"
                    >
                      Lentes de Grau
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={tabs === 1}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: tabs === 1,
                      })}
                      role="tab"
                      onClick={() => setTabs(1)}
                      href="#"
                    >
                      Lentes de Descanso
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={tabs === 2}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: tabs === 2,
                      })}
                      role="tab"
                      onClick={() => setTabs(2)}
                      href="#"
                    >
                      Solar com Grau
                    </NavLink>
                  </NavItem>
                </Nav>
                <Row className="mt-3">
                  <Col xs={12} md={5}>
                    <Input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Nome do material ou opção"
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Input
                      value={variationType}
                      onChange={(e) => setVariationType(e.target.value)}
                      className="my-2 my-md-0"
                      type="select"
                      name="select"
                    >
                      <option value="0">Material da Lente</option>
                      <option value="1">Opções de Lente</option>
                    </Input>
                  </Col>
                  <Col xs={12} md={3}>
                    <Button
                      className="float-md-left float-right"
                      color="success"
                      outline
                      onClick={(e) => submit(e)}
                    >
                      <i className="ni ni-fat-add" />
                      Adicionar
                    </Button>
                  </Col>
                </Row>
                {error && (
                  <Alert className="mt-3 mx-md-8" color="warning">
                    <span className="alert-inner--icon">
                      <i className="ni ni-bell-55" />
                    </span>{" "}
                    <span className="alert-inner--text">
                      <strong>Atenção!</strong> {error}
                    </span>
                  </Alert>
                )}
                <TabContent activeTab={"tabs" + tabs}>
                  <TabPane tabId="tabs0">
                    <Row className="mt-3">
                      <Col xs={12} md={6}>
                        <h4>Materiais da Lente</h4>
                        {!!variations &&
                          variations
                            .filter((variation) => {
                              return (
                                variation.variationType === 0 &&
                                variation.categoryType === 0
                              );
                            })
                            .map((each) => (
                              <Flag
                                deleteVariation={deleteVariation}
                                id={each.idVariation}
                                key={each.idVariation}
                                name={each.name}
                              />
                            ))}
                      </Col>
                      <Col xs={12} md={6}>
                        <h4>Opções da Lente</h4>
                        {!!variations &&
                          variations
                            .filter((variation) => {
                              return (
                                variation.variationType === 1 &&
                                variation.categoryType === 0
                              );
                            })
                            .map((each) => (
                              <Flag
                                deleteVariation={deleteVariation}
                                id={each.idVariation}
                                key={each.idVariation}
                                name={each.name}
                              />
                            ))}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="tabs1">
                    <Row className="mt-3">
                      <Col xs={12} md={6}>
                        <h4>Materiais da Lente</h4>
                        {!!variations &&
                          variations
                            .filter((variation) => {
                              return (
                                variation.variationType === 0 &&
                                variation.categoryType === 1
                              );
                            })
                            .map((each) => (
                              <Flag
                                deleteVariation={deleteVariation}
                                id={each.idVariation}
                                key={each.idVariation}
                                name={each.name}
                              />
                            ))}
                      </Col>
                      <Col xs={12} md={6}>
                        <h4>Opções da Lente</h4>
                        {!!variations &&
                          variations
                            .filter((variation) => {
                              return (
                                variation.variationType === 1 &&
                                variation.categoryType === 1
                              );
                            })
                            .map((each) => (
                              <Flag
                                deleteVariation={deleteVariation}
                                id={each.idVariation}
                                key={each.idVariation}
                                name={each.name}
                              />
                            ))}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="tabs2">
                    <Row className="mt-3">
                      <Col xs={12} md={6}>
                        <h4>Materiais da Lente</h4>
                        {!!variations &&
                          variations
                            .filter((variation) => {
                              return (
                                variation.variationType === 0 &&
                                variation.categoryType === 2
                              );
                            })
                            .map((each) => (
                              <Flag
                                deleteVariation={deleteVariation}
                                id={each.idVariation}
                                key={each.idVariation}
                                name={each.name}
                              />
                            ))}
                      </Col>
                      <Col xs={12} md={6}>
                        <h4>Opções da Lente</h4>
                        {!!variations &&
                          variations
                            .filter((variation) => {
                              return (
                                variation.variationType === 1 &&
                                variation.categoryType === 2
                              );
                            })
                            .map((each) => (
                              <Flag
                                deleteVariation={deleteVariation}
                                id={each.idVariation}
                                key={each.idVariation}
                                name={each.name}
                              />
                            ))}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
