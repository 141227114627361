import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  CardImg,
  Alert,
} from "reactstrap";

import "./styles.scss";
import logo from "../../../assets/img/logo_dark_crm.png";
import api from "config/api";
import Loading from "components/Loading";

export default function Login() {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    saveStore();
  }, []);

  async function saveStore() {
    setLoading(true);
    try {
      const { data } = await api.post(`authorize${location.search}`);

      setToken(data.forgotToken);
      setEmail(data.email);
    } catch (error) {
      Swal.fire(
        "Erro",
        "Ocorreu um Erro ao registrar os dados da sua loja no nosso aplicativo, contate a equipe técnica!",
        "error"
      ).then(() => {
        history.goBack();
      });
    }
    setLoading(false);
  }

  async function submit(e) {
    e.preventDefault();
    if (!password) {
      setError("Preecha a senha por favor!");
      return;
    }
    if (password.length < 6) {
      setError("A senha precisa ter pelo menos 6 dígitos!");
      return;
    }
    if (password !== confirmPassword) {
      setError("As senhas não coincidem!");
      return;
    }

    setLoading(true);
    try {
      await api.post("reset-password", { email, password, token });

      Swal.fire("Salvo", "Senha cadastrada com sucesso", "success").then(() => {
        history.push("/login");
      });
    } catch (error) {
      setError("Tente novamente ou contate a equipe técnica");
    }
    setLoading(false);
  }

  return (
    <div className="div-container">
      {loading && <Loading />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-transparent p-5"
          >
            <CardImg src={logo} alt="logo" />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Cadastrar Senha Para Acessar o Dashboard</small>
            </div>
            {error && (
              <Alert
                style={{
                  backgroundColor: "#d32c2c47",
                  border: "none",
                  color: "#000",
                }}
              >
                <p>
                  <strong>Erro!</strong> {error}
                </p>
              </Alert>
            )}
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Senha"
                    type="password"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirmar Senha"
                    type="password"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  onClick={(e) => submit(e)}
                  className="my-4"
                  color="primary"
                  type="button"
                >
                  Salvar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}
