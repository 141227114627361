import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Alert,
} from "reactstrap";

import Loading from "components/Loading";
import api from "config/api"; 

import "./styles.scss";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function submit(e) {
    e.preventDefault();

    if(!email) {
      setError('Preencha o e-mail por favor');
      return;
    }

    setLoading(true)
    try {
      await api.post('request-reset-password', { email });

      history.push("/login")
    } catch (error) {
      setError("Verifique o e-mail e tente novamente!")
    }
    setLoading(false);
  }
  return (
    <div className="div-container">
      {loading && <Loading />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div>
              <Link to="/login">
                <i className="fa fa-arrow-left" /> Voltar
              </Link>
            </div>
            <div className="text-center text-muted mb-4">
              <small>Informe seu e-mail para criar uma nova senha!</small>
              {error && (
                <Alert
                  style={{
                    backgroundColor: "#d32c2c47",
                    border: "none",
                    color: "#000",
                  }}
                >
                  <p>
                    <strong>Erro!</strong> {error}
                  </p>
                </Alert>
              )}
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    type="email"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button onClick={e => submit(e)} className="my-4" color="primary" type="button">
                  Enviar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}
