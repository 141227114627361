import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  Button,
} from "reactstrap";

import Header from "components/Header";
import Loading from "components/Loading";

import api from "config/api";
import Swal from "sweetalert2";

export default function Settings() {
  const [queryString, setQueryString] = useState("");
  const [position, setPosition] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    loadSetting();
  }, []);

  async function loadSetting() {
    setLoading(true);
    try {
      const { data } = await api.get("/setting");

      setQueryString(data.queryString);
      setPosition(data.idPosition);
    } catch (error) {
      if (error.response.status === 401) {
        console.log(error.response.status);
        Swal.fire(
          "Token Expirado",
          "Por favor, efetue login novamente!",
          "info"
        ).then(() => {
          sessionStorage.clear();
          history.push("/admin");
        });
      } else {
        Swal.fire(
          "Erro",
          "Ocorreu um Erro ao carregar os dados, contate a equipe técnica!",
          "error"
        ).then(() => {
          history.push("/admin/receitas");
        });
      }
    }
    setLoading(false);
  }
  async function submit(e) {
    e.preventDefault();

    Swal.fire({
      title: "Atenção",
      text: "Esta alteração é avançada, tem certeza que deseja alterar?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Alterar",
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.put("/setting", {
            queryString,
            idPosition: position,
          });
        } catch (error) {
          if (error.response.status === 401) {
            console.log(error.response.status);
            Swal.fire(
              "Token Expirado",
              "Por favor, efetue login novamente!",
              "info"
            ).then(() => {
              sessionStorage.clear();
              history.push("/admin");
            });
          } else {
            Swal.fire(
              "Erro",
              "Ocorreu um Erro ao salvar os dados, contate a equipe técnica!",
              "error"
            );
          }
        }
        setLoading(false);
      }
    });
  }
  return (
    <>
      <Header />
      {loading && <Loading />}
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-secondary shadow">
              <CardHeader className="bg-transparent text-default">
                <strong>Configurações</strong>
              </CardHeader>
              <CardBody>
                <Label for="reference">
                  Query String do Elemento Dom para referência para adicionar o
                  botão (avançado)
                </Label>
                <Input
                  value={queryString}
                  onChange={(e) => setQueryString(e.target.value)}
                  id="reference"
                  placeholder="#categories-column"
                />
                <Label className="mt-4" for="position">
                  Posição em que ele deve ser adicionado (avançado)
                </Label>
                <Input
                  value={position}
                  onChange={(e) => setPosition}
                  type="select"
                  id="position"
                >
                  <option value={1}>Antes do elemento</option>
                  <option value={2}>Depois do elemento</option>
                  <option value={3}>Dentro do elemento, no começo</option>
                  <option value={4}>Dentro do elemento, no final</option>
                </Input>
                <Button
                  onClick={(e) => submit(e)}
                  className="float-right mt-4"
                  color="success"
                  outline
                >
                  Salvar
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
