import React from "react";

import "./styles.scss";

export default function Flag({ name, deleteVariation, id }) {
  return (
    <span className="flag-container">
      {name}
      <i
        onClick={() => deleteVariation(id)}
        className="ni ni-fat-remove ml-1"
      />
    </span>
  );
}
