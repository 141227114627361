import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import Cookies from "universal-cookie";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  CardImg,
  Alert,
} from "reactstrap";

import "./styles.scss";
import logo from "../../../assets/img/logo_dark_crm.png";
import api from "../../../config/api";
import Loading from "../../../components/Loading";

export default function Login() {
  const history = useHistory();
  const cookies = new Cookies();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    searchForCookies();
  }, []);

  async function searchForCookies() {
    setEmail(cookies.get("email"));
  }

  async function submit() {
    if (!email) {
      setError("Preencha o e-mail por favor!");
      return;
    }
    if (!password) {
      setError("Preecha a senha por favor!");
      return;
    }
    if (password.length < 6) {
      setError("A senha precisa ter pelo menos 6 dígitos!");
      return;
    }
    setLoading(true);

    try {
      const { data } = await api.post("authenticate", { email, password });

      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("name", data.name);
      sessionStorage.setItem("domain", data.nuvemShopOriginalDomain);
      sessionStorage.setItem("storeId", data.nuvemShopIdStore);

      cookies.remove("email")
      cookies.set("email", email);

      history.push("/admin/receitas");
    } catch (error) {
      setError("E-mail ou senha inválidos!");
    }

    setLoading(false);
  }
  return (
    <div className="div-container">
      {loading && <Loading />}
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="bg-transparent p-5"
          >
            <CardImg src={logo} alt="logo" />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Credenciais</small>
            </div>
            {error && (
              <Alert
                style={{
                  backgroundColor: "#d32c2c47",
                  border: "none",
                  color: "#000",
                }}
              >
                <p>
                  <strong>Erro!</strong> {error}
                </p>
              </Alert>
            )}
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    type="email"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13)
                        document.getElementsByName("password")[0].focus();
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Senha"
                    name="password"
                    type="password"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) submit();
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  onClick={() => submit()}
                  className="my-4"
                  color="primary"
                  type="button"
                >
                  Acessar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link to="/forgot-password">
              <small>Esqueceu sua senha?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
