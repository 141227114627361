import React, { useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom"

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";

import api from "config/api";
import Header from "components/Header";
import Loading from "components/Loading";

export default function UserProfile() {
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function submit(e) {
    e.preventDefault();

    if (!oldPassword || !newPassword || !confirmPassword) {
      setError("Preencha todos os campos!");
      return;
    }
    if (
      newPassword.length < 6 ||
      oldPassword.length < 6 ||
      confirmPassword.length < 6
    ) {
      setError("A senha precisa precisa ter pelo menos 6 dígitos!");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("As senhas não coincidem!");
      return;
    }

    setLoading(true);
    try {
      await api.post("change-password", { oldPassword, newPassword });

      Swal.fire("Sucesso", "Senha alterada com sucesso", "success");
      setError("");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      if (error.response.status === 401) {
        console.log(error.response.status);
        Swal.fire(
          "Token Expirado",
          "Por favor, efetue login novamente!",
          "info"
        ).then(() => {
          sessionStorage.clear();
          history.push("/admin");
        });
      } else {
        setError("Verifique os dados e tente novamente");
      }
    }
    setLoading(false);
  }
  return (
    <>
      <Header />
      {loading && <Loading />}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-transparent">
                <div>
                  <strong>Alterar Senha</strong>
                </div>
              </CardHeader>
              <CardBody>
                <Label>Senha Antiga:</Label>
                <Input
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  type="password"
                />
                <Label>Senha Nova:</Label>
                <Input
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  type="password"
                />
                <Label>Confirmar Nova Senha:</Label>
                <Input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                />
                {error && (
                  <Alert
                    className="mt-3"
                    style={{
                      backgroundColor: "#d32c2c47",
                      border: "none",
                      color: "#000",
                    }}
                  >
                    <p>
                      <strong>Erro!</strong> {error}
                    </p>
                  </Alert>
                )}
                <Button
                  onClick={(e) => submit(e)}
                  type="button"
                  className="mt-2 float-right"
                  color="success"
                  outline
                >
                  Salvar
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
